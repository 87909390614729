import styled from '@emotion/styled';
import { DocumentType, MediaType } from '@innedit/innedit-type';
import compact from 'lodash/compact';
import { MediaData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import Media from '~/components/Media';
import ViewModal from '~/components/View/Modal';
import ListCard from '~/containers/Espace/Card';
import { ListItemProps } from '~/containers/Espace/List/props';
import IconDelete from '~/icons/Delete';
import IconEye from '~/icons/Eye';
import IconEyeSlash from '~/icons/EyeSlash';
import ImagePhoto from '~/images/Photo';
import colors from '~/styles/colors';

const BodyCard = styled.div`
  em {
    color: ${colors.secondary[500]};
    font-weight: 600;
  }
`;

const ListCardMedia: FC<ListItemProps<MediaType, MediaData>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [openDelete, setOpenDelete] = useState<boolean>();
  const [doc, setDoc] = useState<DocumentType<MediaType>>();
  const { t } = useTranslation();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return (
      <li className="flex flex-col rounded !p-0">
        <div className="relative flex flex-auto flex-col-reverse bg-white-50 pb-full">
          <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
            <ImagePhoto color={colors.light[600]} size={100} />
          </div>
        </div>
      </li>
    );
  }

  // let photo;
  // try {
  //   if (doc.fullPath) {
  //     if (['audio/', 'video/'].includes(doc.type.slice(0, 'audio/'.length))) {
  //       photo = nextPhoto;
  //     } else {
  //       switch (doc.type) {
  //         case 'image/jpeg':
  //         case 'image/png':
  //         case 'image/webp': {
  //           photo = `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${
  //             doc.fullPath
  //           }?w=320&h=320&c=max`;
  //           break;
  //         }
  //
  //         case 'image/svg+xml':
  //         default: {
  //           photo = `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${
  //             doc.fullPath
  //           }`;
  //           break;
  //         }
  //       }
  //     }
  //   } else {
  //     photo = nextPhoto;
  //   }
  // } catch (e) {
  //   photo = nextPhoto;
  // }

  const handleOnClick = (event: SyntheticEvent<HTMLElement>) => {
    onClick(event);
  };

  const handleHiddenOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();

    if (doc) {
      model.secureUpdate(doc.id, {
        hidden: !doc.hidden,
      });
    }
  };

  // const handleDeleteOnClick = () => {};

  const handleDeleteOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ): Promise<void> => {
    event.preventDefault();

    setOpenDelete(true);
  };

  const handleDeleteDoc = async () => {
    if (doc && doc.id) {
      await toast.promise(model.delete(doc.id), {
        error: 'Problème lors de la suppression',
        pending: 'Suppression en cours',
        success: 'Suppression réussie',
      });
    } else {
      await toast(`${t('deleteError')} : no item id`);
    }
  };

  const handleDeleteConfirmOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    await handleDeleteDoc();
    setOpenDelete(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  return (
    <ListCard
      actions={compact([
        {
          iconLeft: !doc.hidden ? IconEye : IconEyeSlash,
          onClick: handleHiddenOnClick,
        },
        {
          iconLeft: IconDelete,
          onClick: handleDeleteOnClick,
        },
      ])}
      displayEdit={false}
      displayId={false}
      doc={doc}
      index={index}
      onClick={handleOnClick}
    >
      {openDelete && (
        <ViewModal
          closeOnClick={handleCloseDelete}
          maxWidth="500px"
          title="Suppression"
        >
          <p>Est-ce que vous souhaitez vraiment supprimer ce document ?</p>
          <div className="mt-3 flex items-center justify-center space-x-3">
            <Button
              color="neutral"
              onClick={handleCloseDelete}
              variant="outline"
            >
              Non
            </Button>
            <Button
              color="danger"
              onClick={handleDeleteConfirmOnClick}
              variant="solid"
            >
              Oui
            </Button>
          </div>
        </ViewModal>
      )}
      <figure
        className="cursor-pointer rounded-t border-b"
        data-id={doc.id}
        onClick={handleOnClick}
        role="presentation"
      >
        <Media
          media={{
            ...doc,
            origin: String(process.env.GATSBY_IMAGES_DOMAIN_NAME),
            pathname: doc.fullPath,
          }}
          options={{
            crop: 'contain',
          }}
          thumbnail
        />
      </figure>

      <BodyCard className="text-sm px-2 py-0.5">
        <h2 className="truncate text-center">{doc.label}</h2>
        <p className="mt-[1px] text-[12px] text-light-700">{doc.type}</p>
      </BodyCard>
    </ListCard>
  );
};

export default ListCardMedia;
