import { DocumentType, MediaType } from '@innedit/innedit-type';
import { MediaData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';

import nextPhoto from '../../../images/next-photo.svg';
import ListItem from './index';

const ListItemMedia: FC<ListItemProps<MediaType, MediaData>> = ({
  docId,
  index,
  model,
  onClick,
}) => {
  const [doc, setDoc] = useState<DocumentType<MediaType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  let photo;
  try {
    if (doc.fullPath) {
      if (['audio/', 'video/'].includes(doc.type.slice(0, 'audio/'.length))) {
        photo = nextPhoto;
      } else {
        switch (doc.type) {
          case 'image/jpeg':
          case 'image/png':
          case 'image/webp': {
            photo = `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${
              doc.fullPath
            }?w=80&h=80&c=max`;
            break;
          }

          case 'image/svg+xml':
          default: {
            photo = `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${
              doc.fullPath
            }`;
            break;
          }
        }
      }
    } else {
      photo = nextPhoto;
    }
  } catch (e) {
    photo = nextPhoto;
  }

  let description = `${doc.parentCollectionName}`;

  if (doc.width && doc.height) {
    description += ` - ${doc.width} x ${doc.height} px`;
  }

  return (
    <ListItem
      date={doc.createdAt}
      description={description}
      displayActionId={false}
      doc={doc}
      icon={
        <figure className="aspect-ratio aspect-ratio--square">
          <img alt="Chargement" className="aspect-ratio__content" src={photo} />
        </figure>
      }
      index={index}
      label={doc.label}
      onClick={onClick}
      subLabel={doc.type}
    />
  );
};

export default ListItemMedia;
